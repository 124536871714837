import { Noise, Volume, Panner, Filter } from 'tone';

export class AudioManager {
  public getNoise(type: 'white' | 'pink' | 'brown') {
    return new Noise(type);
  }

  public getVolume(value: number) {
    return new Volume(value);
  }

  public getPanner(side: 'right' | 'left') {
    if (side === 'right') {
      return new Panner(1);
    } else {
      return new Panner(-1);
    }
  }

  public getFilter() {
    return new Filter();
  }
  public getEQFilter(frequency: number) {
    const filter = new Filter(frequency, 'peaking');
    return filter;
  }
}
