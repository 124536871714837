export function calculateTan(threshold: number, mml: number, overallThreshold: number) {
  if (threshold >= mml) {
    return threshold - overallThreshold;
  } else {
    const tan = threshold + (mml - threshold) * 0.4;
    return Math.round(tan * 10) / 10 < 0 ? 0 : Math.round(tan * 10) / 10;
  }
}

export function calculateDbThreshold(threshold: number[]) {
  const minThreshold = 0;
  const maxThreshold = 120;
  const minThresholdDB = -90;
  const maxThresholdDB = 10;

  const thresholdDb = threshold.map((value) => {
    const valueDb =
      ((value - minThreshold) / (maxThreshold - minThreshold)) * (maxThresholdDB - minThresholdDB) + minThresholdDB;
    return valueDb;
  });
  return thresholdDb;
}

export function calculateDbMML(MML: number[]) {
  const minMML = 0;
  const maxMML = 120;
  const minMMLDB = -90;
  const maxMMLdDB = 10;
  const mmlDb = MML.map((value) => {
    const valueDb = ((value - minMML) / (maxMML - minMML)) * (maxMMLdDB - minMMLDB) + minMMLDB;
    return valueDb;
  });
  return mmlDb;
}

export function calculateDbTan(thresholdDb: number[], mmlDb: number[]) {
  const dbTan = thresholdDb.map((thresholdValue, index) => {
    const valueDb = thresholdValue + (mmlDb[index] - thresholdValue) * 0.4;
    return valueDb;
  });
  return dbTan;
}

export function calculateOverallThresholdDb(OverallThreshold: number) {
  const minOverallThreshold = 0;
  const maxOverallThreshold = 120;
  const minOverallThresholdDB = -90;
  const maxOverallThresholdDB = 10;
  const overallThresholdDb =
    ((OverallThreshold - minOverallThreshold) / (maxOverallThreshold - minOverallThreshold)) *
      (maxOverallThresholdDB - minOverallThresholdDB) +
    minOverallThresholdDB;
  return overallThresholdDb;
}

export function convertTanDb(tanDb: number[]) {
  const minTan = 0;
  const maxTan = 120;
  const minTanDB = -90;
  const maxTandDB = 10;
  const tan = tanDb.map((value) => {
    const valueDb = ((value - minTanDB) / (maxTandDB - minTanDB)) * (maxTan - minTan) + minTan;
    return Number(Math.round(valueDb * 100) / 100);
  });
  return tan;
}
