import { useEffect, useMemo, useState } from 'react';
import { Box, Slider, IconButton, Typography, useTheme, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PlayBoldSVG from '../../../assets/img/play-bold.svg';
import StopBoldSVG from '../../../assets/img/stop-bold.svg';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { AudioManager } from '../../../utils/audio-manager';
import { linearToDecibel } from '../../../utils/volume-utils';

interface EqSimulateProps {
  tan: number[];
  overallThreshold: number;
}

const MAX_VALUE = 120;
const MIN_VALUE = 0;

const frequencyBand = [
  // { frequency: 16, lowerLimit: 11, upperLimit: 22 },
  // { frequency: 31.5, lowerLimit: 22, upperLimit: 44 },
  // { frequency: 63, lowerLimit: 44, upperLimit: 88 },
  // { frequency: 125, lowerLimit: 88, upperLimit: 177 },
  // { frequency: 250, lowerLimit: 177, upperLimit: 355 },
  { frequency: 500, lowerLimit: 355, upperLimit: 710 },
  { frequency: 1000, lowerLimit: 710, upperLimit: 1420 },
  { frequency: 2000, lowerLimit: 1420, upperLimit: 2840 },
  { frequency: 4000, lowerLimit: 2840, upperLimit: 5680 },
  { frequency: 8000, lowerLimit: 5680, upperLimit: 11360 },
  { frequency: 16000, lowerLimit: 11360, upperLimit: 22720 },
];

function EqSimulate(props: EqSimulateProps) {
  const theme = useTheme();

  const [isPlay, setIsPlay] = useState(false);
  const [volumLevel, setVolumLevel] = useState(0);

  const toneObj = useMemo(() => {
    const audioManager = new AudioManager();
    const noise = audioManager.getNoise('white');
    noise.volume.value = -100;
    const filters = new Array(6);
    frequencyBand.forEach((band, index) => {
      filters[index] = audioManager.getEQFilter(band.frequency);
    });

    noise.connect(filters[0]);
    for (let index = 0; index < frequencyBand.length - 1; index++) {
      filters[index].connect(filters[index + 1]);
    }
    filters[5].toDestination();
    return { noise, filters };
  }, []);

  useEffect(() => {
    return () => {
      setIsPlay(false);
      toneObj.noise.stop();
    };
  }, []);

  useEffect(() => {
    document.onkeydown = handleOnKeyDown;
    return () => {
      document.onkeydown = null;
    };
  }, [volumLevel]);

  const handlePlayOnClick = () => {
    if (isPlay) {
      setIsPlay(false);
      toneObj.noise.stop();
    } else {
      const volumeDB = linearToDecibel(volumLevel, -100, -40);
      handleEqChangeToVolume(volumeDB);
      setIsPlay(true);
      toneObj.noise.start();
    }
  };

  const handleVolumChange = (value: number) => {
    setVolumLevel(value);
    const volumeDB = linearToDecibel(value, -100, -40);
    toneObj.noise.volume.value = volumeDB;
    handleEqChangeToVolume(volumeDB);
  };

  const handleEqChangeToVolume = (volumeDB: number) => {
    toneObj.filters.forEach((filter, index) => {
      var gainDB = 0.4 * (linearToDecibel(props.tan[index], -100, 0, { min: -90, max: 10 }) - volumeDB);
      filter.Q.value = Math.sqrt(2);
      filter.gain.value = gainDB;
    });
  };

  const handleVolumeIncrease = () => {
    if (volumLevel < 120) {
      handleVolumChange(volumLevel + 1);
    }
  };

  const handleVolumeDecrease = () => {
    if (volumLevel > 0) {
      handleVolumChange(volumLevel - 1);
    }
  };

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    handleVolumChange(newValue as number);
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    const keyPressValueMap = new Map<string, number>([
      ['ArrowUp', 1],
      ['ArrowDown', -1],
      ['+', 5],
      ['-', -5],
    ]);

    const pressKey = event.key;
    if (keyPressValueMap.has(pressKey)) {
      const value = volumLevel + Number(keyPressValueMap.get(pressKey));
      if (value > MAX_VALUE) {
        handleVolumChange(MAX_VALUE);
      } else if (value < MIN_VALUE) {
        handleVolumChange(MIN_VALUE);
      } else {
        handleVolumChange(value);
      }
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <VolumeUpIcon color="primary" />
            <Slider value={volumLevel} onChange={handleSliderChange} sx={{ marginX: 4 }} step={1} min={0} max={120} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor={theme.palette.white.main}
              width={140}
              sx={{ borderWidth: '0.5px', borderStyle: 'solid', borderRadius: '10px' }}
            >
              <IconButton size="small" onClick={handleVolumeDecrease}>
                <RemoveCircleOutlineIcon color="primary" />
              </IconButton>
              <Typography>{volumLevel}</Typography>
              <IconButton size="small" onClick={handleVolumeIncrease}>
                <ControlPointIcon color="primary" />
              </IconButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingButton
            sx={{ borderRadius: '10px' }}
            onClick={handlePlayOnClick}
            endIcon={<Box width={24} height={24} component="img" src={isPlay ? StopBoldSVG : PlayBoldSVG} />}
          >
            {isPlay ? 'Stop Demonstrate' : 'Demonstrate'}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}

export default EqSimulate;
