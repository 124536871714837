import React from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import * as _ from 'lodash';
import EarphoneIcon from '../../../assets/img/earphone.svg';
import { patientActions } from '../../../redux/patient/patientSlice';

function EarphoneAlert() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const componentStatus = useAppSelector((state: RootState) => state.patient.componentStatus);
  const isEarphoneAlertOpen = _.get(componentStatus, 'isEarphoneAlertOpen', true) as boolean;

  const handleDismissOnClick = () => {
    dispatch(patientActions.setComponentStatus({ isEarphoneAlertOpen: false }));
  };

  return (
    <CustomModal
      modal={true}
      open={isEarphoneAlertOpen}
      title="Attention!"
      icon={EarphoneIcon}
      maxWidth="374px"
    >
      <Typography component="p" variant="bodyRegular" textAlign="center" color={theme.palette.info.main}>
      Please ensure that earphones are connected to your device and that sound at a safe volume before fitting.
      </Typography>
      <Box marginTop={4} display="flex" columnGap={2} justifyContent='center'>
        <Button
          variant="outlined"
          color="primary"
          sx={{ borderRadius: '10px', height: '43px', width: '101px'  }}
          onClick={handleDismissOnClick}
        >
          Got It
        </Button>
      </Box>
    </CustomModal>
  );
}

export default EarphoneAlert;
