import * as yup from 'yup';

export const validation = {
  firstName: yup
    .string()
    .max(30, 'First name should not contain more than 30 characters')
    .matches(/^[a-zA-Z ]*$/, 'First name cannot contain special character and numbers.')
    .required('First name is required'),
  lastName: yup
    .string()
    .max(30, 'Last name should not contain more than 30 characters')
    .matches(/^[a-zA-Z ]*$/, 'Last name cannot contain special character and numbers.')
    .required('Last name is required'),
  surname: yup
    .string()
    .max(30, 'Middle name should not contain more than 30 characters')
    .matches(/^[a-zA-Z ]*$/, 'Middle name cannot contain special character and numbers.')
    .optional(),
  email: yup
    .string()
    .max(50, 'Email should not contain more than 50 characters')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, { message: 'Please enter a valid email address' })
    .test(
      'gmail-validation',
      'Please enter a valid email address',
      (value) => !!value && ((value.includes('@gmail') && value.endsWith('.com')) || !value.includes('@gmail')),
    )
    .required('Email is required'),
};
