export function linearToDecibel(
  value: number,
  targetRangeMin = -100,
  targetRangeMax = 0,
  sourceRangeVal = { min: 0, max: 120 },
): number {
  const targetRange = targetRangeMax - targetRangeMin;
  const sourceRange = sourceRangeVal.max - sourceRangeVal.min;
  const decimalValue = ((value - sourceRangeVal.min) * targetRange) / sourceRange + targetRangeMin;
  return Math.round(decimalValue * 100) / 100;
}
