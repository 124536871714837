import React, { Fragment, useCallback } from 'react';
import { useTheme, Grid, Typography, IconButton } from '@mui/material';
import * as _ from 'lodash';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import CustomInputBox from '../../../components/CustomInputBox/CustomInputBox';
import CustomButtonLoadingButton from '../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import { soundActions } from '../../../redux/sound/soundSlice';
import { SoundType } from '../../../utils/enums/sound-type.enum';
import ConfirmAddSound from './ConfirmAddSound';
import CustomDropzone from '../../../components/CustomDropzone/CustomDropzone';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import EditIcon from '@mui/icons-material/Edit';
import EditAlbumArt from './EditAlbumArt';
import { calculateResizeDimensions } from '../../../utils/image-utils';

interface AddSoundProps {
  isAddOpen: boolean;
}

function AddSound({ isAddOpen }: AddSoundProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const soundTrack = useAppSelector((state: RootState) => state.sound.newSoundTrack);
  const componentStatus = useAppSelector((state: RootState) => state.sound.componentStatus);
  const isAddSoundSuccessOpen = _.get(componentStatus, 'isAddSoundSuccessModalOpen', false) as boolean;
  const isAlbumArtEditOpen = _.get(componentStatus, 'isAlbumArtEditOpen', false) as boolean;

  const soundTypeList = [
    { value: 'Relief Audio', key: SoundType.RELIEF },
    { value: 'Relax Audio', key: SoundType.RELAX },
    { value: 'Retrain Audio', key: SoundType.RETRAIN },
  ];

  const initialValues = {
    type: soundTrack.soundData.type,
    title: soundTrack.soundData.title,
    duration: soundTrack.soundData.duration,
    file: soundTrack.audio,
    imgFile: soundTrack.soundData.albumArt,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: yup.object({
      type: yup.string().required('Sound type is required'),
      title: yup.string().max(150).required('Please set the Title to continue'),
      duration: yup
        .number()
        .min(1, 'Please upload the audio file to continue'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (values.file?.type === 'audio/mpeg' && values.type === SoundType.RELIEF) {
        return;
      }
      const soundData = {
        title: values.title,
        type: values.type,
        duration: values.duration,
      };
      if (values.imgFile) {
        const response = await fetch(values.imgFile);
        const blob = await response.blob();
        const image = new File([blob], `${blob.type.replace('/', '.')}`, { type: blob.type });
        dispatch(soundActions.setNewSoundTrackAndOpenSuccessModal({ soundData, audio: values.file, image }));
      } else {
        dispatch(soundActions.setNewSoundTrackAndOpenSuccessModal({ soundData, audio: values.file, image: null }));
      }
    },
  });

  const handleAddModalClose = () => {
    formik.resetForm();
    dispatch(soundActions.clearComponentStatus());
    dispatch(soundActions.setComponentStatus({ isAddSoundPopupOpen: false }));
    dispatch(soundActions.clearAddNewSoundTrack());
  };

  const onDropAccepted = useCallback((files: File[]) => {
    const url = URL.createObjectURL(files[0]);
    formik.setFieldValue('file', files[0]);
    const audio = new Audio(url);
    audio.addEventListener('loadedmetadata', () => {
      formik.setFieldValue('duration', audio.duration * 1000);
    });

    audio.load();
  }, []);

  const onDropAcceptedImage = useCallback((files: File[]) => {
    const url = URL.createObjectURL(files[0]);
    const img = new Image();
    img.src = url;
    img.onload = () => {
      const maxWidth = 517;
      const maxHeight = 494;

      const { width: newWidth, height: newHeight } = calculateResizeDimensions(
        maxWidth,
        maxHeight,
        img.width,
        img.height,
      );

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = newWidth;
      canvas.height = newHeight;
      if (ctx) {
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const resizedDataURL = canvas.toDataURL();
        dispatch(soundActions.setComponentStatus({ albumArt: resizedDataURL }));
        dispatch(soundActions.setComponentStatus({ isAlbumArtEditOpen: true }));
      }
    };
  }, []);

  const getDuration = () => {
    const timeInSeconds = formik.values.duration / 1000;
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes < 10 ? '0' + minutes : minutes} : ${seconds < 10 ? '0' + seconds : seconds} `;
  };

  const handleEditAlbumArt = () => {
    dispatch(soundActions.setComponentStatus({ isAlbumArtEditOpen: true }));
  };

  React.useEffect(() => {
    const soundData = {
      title: formik.values.title,
      type: formik.values.type,
      duration: formik.values.duration,
      albumArt: soundTrack.soundData.albumArt,
    };
    const audio = formik.values.file;
    dispatch(soundActions.setSoundData({ soundData, audio, image: soundTrack.image }));
  }, [formik.values]);

  const handleDeleteFilesAudio = () => {
    formik.setFieldValue('duration', 0);
    formik.setFieldValue('file', null);
  };

  const handleDeleteFilesAlbumArt = () => {
    formik.setFieldValue('imgFile', '');
    dispatch(soundActions.setAlbumArtSrc(''));
    dispatch(soundActions.setComponentStatus({ crop: { x: 0, y: 0, width: 200, height: 200, unit: 'px' } }));
    dispatch(soundActions.setComponentStatus({ savedCrop: { x: 0, y: 0, width: 200, height: 200, unit: 'px' } }));
  };

  return (
    <Fragment>
      <CustomModal modal={true} open={isAddOpen} title="Add new sound" maxWidth="581px" align="left">
        <Grid container marginTop={1} rowSpacing={2} columnSpacing={1}>
          <Grid item xs={12}>
            <CustomInputBox
              label="Sound Type"
              required={true}
              type="text"
              select={true}
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
              selectOptions={soundTypeList}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInputBox
              label="Title"
              required={true}
              type="text"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDropzone
              accept={{
                'audio/wav': ['.wav'],
                ...(formik.values.type === SoundType.RELAX && { 'audio/mpeg': ['.mp3'] }),
                ...(formik.values.type === SoundType.RETRAIN && { 'audio/mpeg': ['.mp3'] }),
              }}
              maxFiles={1}
              dargActiveText={'Drop the audio file here'}
              dragInactiveText={'Drag and drop the audio file here'}
              invalidFileTypeError={
                formik.values.type === SoundType.RELAX
                  ? 'Please upload your relax audio file in wav/mp3 format.'
                  : formik.values.type === SoundType.RETRAIN
                  ? 'Please upload your retrain audio file in wav/mp3 format.'
                  : 'Please upload your relief audio file in wav format.'
              }
              tooLargeFileError={'Please upload an file less than 50MB in size'}
              onDropAccepted={onDropAccepted}
              handleDelteFiles={handleDeleteFilesAudio}
              formikError={formik.errors.duration ?? ''}
              acceptedFile={formik.values.file}
              soundType={formik.values.type}
              fileDetails={
                <Grid container display="flex" alignItems="center" spacing={4}>
                  <Grid item>
                    <AudiotrackIcon />
                  </Grid>
                  <Grid item>
                    <Grid display="flex" flexDirection="column">
                      <Grid item>
                        <Typography component="p" variant="button">
                          {formik.values.title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="p" variant="button">
                          {getDuration()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <CustomDropzone
              accept={{
                'image/jpeg': ['.jpeg'],
                'image/png': ['.png'],
              }}
              maxFiles={1}
              maxSize={1024 * 1024 * 5}
              dargActiveText={'Drop the album art here'}
              dragInactiveText={'Drag and drop the album art here'}
              invalidFileTypeError={'Please upload the album art in either jpeg or png format'}
              onDropAccepted={onDropAcceptedImage}
              tooLargeFileError={'Please upload an image less than 5MB in size'}
              handleDelteFiles={handleDeleteFilesAlbumArt}
              formikError={formik.errors.imgFile ?? ''}
              acceptedFile={(formik.values.imgFile as unknown as File) ?? null}
              fileDetails={
                <Grid container display="flex" alignItems="center" spacing={4}>
                  <Grid item>
                    <img
                      src={formik.values.imgFile}
                      alt="album art"
                      width="104.66px"
                      height="100px"
                      style={{ borderRadius: '15px' }}
                    />
                  </Grid>
                  <Grid item>
                    <Grid display="flex" flexDirection="column">
                      <Grid item>
                        <Typography component="p" variant="button">
                          Album Art
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
              fileDetailsAction={
                <Grid item>
                  <IconButton role="album-art-edit-btn" onClick={handleEditAlbumArt}>
                    <EditIcon color="primary" />
                  </IconButton>
                </Grid>
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          marginTop={2}
          justifyContent="space-between"
          sx={{ paddingTop: '30px', borderTop: `1px solid ${theme.palette.shades.purpleBorder}` }}
        >
          <CustomButtonLoadingButton
            text="Dismiss"
            variant="outlined"
            color="primary"
            fullWidth={false}
            onClick={handleAddModalClose}
            width="118px"
          />
          <CustomButtonLoadingButton
            //loading={isSubmitting}
            text="Add Sound"
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={() => formik.handleSubmit()}
            width="118px"
          />
        </Grid>
      </CustomModal>

      {/*confirm and success message*/}
      <ConfirmAddSound isOpen={isAddSoundSuccessOpen} />
      <EditAlbumArt isOpen={isAlbumArtEditOpen} />
    </Fragment>
  );
}

export default AddSound;
