import React, { Fragment, useState } from 'react';
import { Typography, useTheme, Grid } from '@mui/material';
import * as _ from 'lodash';
import CustomButtonLoadingButton from '../../../../components/CustomButtonLoadingButton/CustomButtonLoadingButton';
import AvatarEdit from '../../../../components/AvatarEdit/AvatarEdit';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import Constant from '../../../../constants/constant.json';
import { clinicActions } from '../../../../redux/clinic/clinicSlice';
import { useAuth } from '../../../../context/AuthContext';

function Confirmation() {
  const theme = useTheme();
  const { user } = useAuth();

  const clinic = useAppSelector((state: RootState) => state.clinic);
  const adminData = clinic.adminData;
  const dispatch = useAppDispatch();

  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = () => {
    const { firstName, lastName, email, phone, permissions } = adminData;
    const apiData: any = {
      firstName,
      lastName,
      permissionIds: permissions.map((permission) => permission.permissionId),
      email,
      phone,
      clinicId: user.clinicId,
    };
    dispatch(clinicActions.createClinicAdminAsync(apiData));
    setIsSubmit(true);
  };

  return (
    <Fragment>
      <Grid marginTop={1} container rowSpacing={2} columnSpacing={1}>
        <Grid key={1} item xs={12} sm={12} md={12}>
          <Typography color={theme.palette.info.main} marginBottom={2} marginTop={2}>
            Please confirm the below details
          </Typography>
        </Grid>
        <Grid key={2} container item xs={12} sm={12} md={2}>
          <AvatarEdit
            editable={false}
            avatar={adminData.avatar}
            name={adminData.firstName}
            name2={adminData.lastName}
            size="100px"
            font={theme.typography.h3}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography key={1} variant="h6" sx={{ wordBreak: 'break-word' }} color={theme.palette.black.main} marginBottom={1}>
            {adminData.firstName} {adminData.lastName}
          </Typography>
          <Typography key={2} color={theme.palette.info.main} sx={{ wordBreak: 'break-word' }}>
            {adminData.email}
          </Typography>
          <Typography key={3} color={theme.palette.info.main}>
            {adminData.phone}
          </Typography>
        </Grid>
        <Grid key={3} item xs={12} sm={12} md={6}>
          <Typography color={theme.palette.info.main} marginBottom={1}>
            Permissions
          </Typography>
          {clinic.permissions.map(({ description, permission, permissionId }, index) => {
            if (_.find(adminData.permissions, { permissionId })) {
              return <Typography key={permissionId}>{description}</Typography>;
            }
            return null;
          })}
        </Grid>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          marginTop={2}
          justifyContent="space-between"
          sx={{ paddingTop: '30px', borderTop: `1px solid ${theme.palette.shades.purpleBorder}` }}
        >
          <CustomButtonLoadingButton
            text="Back"
            variant="outlined"
            color="primary"
            fullWidth={false}
            onClick={() => {
              dispatch(clinicActions.setComponentStatus({ selectedTab: Constant.AdminTabs.CONFIG_PERMISSION.slug }));
            }}
            width="95px"
          />
          <CustomButtonLoadingButton
            text="Confirm"
            variant="contained"
            color="primary"
            fullWidth={false}
            onClick={() => handleSubmit()}
            loading={isSubmit}
            width="118px"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Confirmation;
