import { Fragment, useState, useEffect } from 'react';
import { Typography, useTheme, Box, Stack, IconButton, Grid, Skeleton } from '@mui/material';
import CustomTable, { ColumnProps } from '../../../components/CustomTable/CustomTable';
import AvatarEdit from '../../../components/AvatarEdit/AvatarEdit';
import EyeBoldSVG from '../../../assets/img/eye-bold.svg';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { LoadingButton } from '@mui/lab';
import CustomModal from '../../../components/CustomModal/CustomModal';
import _ from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { authActions } from '../../../redux/auth/authSlice';
import { getAgeFromDateOfBirth } from '../../../utils/time-util';
import { ClinicianType, clinicianActions } from '../../../redux/clinician/clinicianSlice';
import { useAuth } from '../../../context/AuthContext';
import { Roles } from '../../../utils/enums/roles.enum';
import { SIDEBAR_OPTIONS } from '../../../utils/sidebar-schema';
import { SidebarOption } from '../../../utils/enums/sidebar-option.enum';
import { SortOrder } from '../../../utils/enums/sort-order.enum';

function CliniciansTable({ clinicId }: { clinicId: string }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [sortBy, setSortBy] = useState<string>('patientCount');
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);

  const totalClinicianCount = useAppSelector((state: RootState) => state.clinician.totalClinicianCount);
  const cliniciansList = useAppSelector((state: RootState) => state.clinician.clinicianList);
  const componentStatus = useAppSelector((state: RootState) => state.auth.componentStatus);
  const selectedClinician = useAppSelector((state: RootState) => state.clinician.selectedClinician);
  const isSelectedClinicianLoading = _.get(componentStatus, 'isSelectedClinicianLoading', false) as boolean;
  const isClinicianTableLoading = _.get(componentStatus, 'isClinicianTableLoading', false) as boolean;
  const openClinicianDetailsModal = _.get(componentStatus, 'openClinicianDetailsModal', false) as boolean;

  useEffect(() => {
    const offset = (page - 1) * rowsPerPage;
    dispatch(clinicianActions.getClinicianListAsync({ clinicId, offset, limit: rowsPerPage, sortBy, sortOrder }));
  }, [page, rowsPerPage, sortBy, sortOrder, dispatch]);

  const clinicianColumns: ColumnProps[] = [
    [Roles.SUPER_ADMIN, Roles.TRUE_SILENCE_ADMIN].includes(user.role)
      ? {
          key: 'globalId',
          name: 'TSTID',
          enableSort: false,
          align: 'left',
          width: 'auto',
          minWidth: 100,
          resizable: true,
        }
      : {
          key: 'name',
          name: 'Name',
          enableSort: false,
          align: 'left',
          width: 'auto',
          minWidth: 100,
          resizable: true,
        },
    {
      key: 'noOfPatients',
      name: 'No. of Patients',
      enableSort: false,
      align: 'left',
      width: 'auto',
      minWidth: 60,
      resizable: true,
    },
    {
      key: 'action',
      name: '',
      enableSort: false,
      align: 'right',
      width: '10%',
      minWidth: 100,
      resizable: false,
    },
  ];

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(1);
  };

  const handleViewClinicianDetails = (clinician: ClinicianType) => {
    navigate(`/clinicians/${clinician.userId}`, { state: clinician });
  };

  const handleDismiss = () => {
    dispatch(authActions.setComponentStatus({ openClinicianDetailsModal: false }));
    setTimeout(() => {
      dispatch(clinicianActions.setSelectedClinician(undefined));
    }, 500);
  };

  const getFormattedCliniciansList = (cliniciansList: ClinicianType[]) => {
    return cliniciansList.map((clinician) => {
      const fullName = `${clinician.firstName} ${clinician.lastName}`;
      return {
        globalId: clinician.globalId ? clinician.globalId.replace('TSAID', '') : '',
        name: (
          <Box display="flex" gap={1}>
            <AvatarEdit
              editable={false}
              avatar={clinician.avatar}
              name={clinician.firstName}
              name2={clinician.lastName}
              size="24px"
              font={theme.typography.subtitle1}
            />
            <Typography variant="bodyRegular">{fullName}</Typography>
          </Box>
        ),
        noOfPatients: clinician.patientCount,
        action: (
          <Stack direction="row" spacing={1} justifyContent="center">
            <IconButton onClick={() => handleViewClinicianDetails(clinician)} size="small">
              <Box component="img" src={EyeBoldSVG}></Box>
            </IconButton>
          </Stack>
        ),
      };
    });
  };

  return (
    <Fragment>
      <CustomTable
        isLoading={isClinicianTableLoading}
        totalDataRows={totalClinicianCount}
        enablePagination={true}
        selectedPage={page}
        enablePagePerRows={false}
        rowsPerList={[10, 20, 50, 100]}
        selectedRowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortOrder={sortOrder}
        columns={clinicianColumns}
        rows={getFormattedCliniciansList(cliniciansList)}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortByChange={setSortBy}
        handleSortOrderChange={setSortOrder}
        minBodyRowSpace={5}
        customTableTop={
          <Box sx={{ padding: '24px' }} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" component="p" color={theme.palette.black.main}>
              Clinicians
            </Typography>
            <Link
              style={{ textDecoration: 'none', color: theme.palette.info.main }}
              to={`${SIDEBAR_OPTIONS[SidebarOption.CLINICIANS].path}?clinicId=${clinicId}`}
            >
              <Typography variant="button" color={theme.palette.primary.main}>
                View All Clinicians
              </Typography>
            </Link>
          </Box>
        }
      />
      <CustomModal
        modal={true}
        open={openClinicianDetailsModal}
        width="374px"
        title={selectedClinician && `${selectedClinician?.firstName} ${selectedClinician?.lastName}`}
        subTitle="Clinician"
      >
        <Stack
          display={isSelectedClinicianLoading ? 'flex' : 'none'}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Skeleton variant="circular" animation="wave" width={72} height={72} />
          <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem', width: '60%', mt: 2 }} />
          <Skeleton variant="rounded" animation="wave" width="90%" height={90} sx={{ mt: 3 }} />
        </Stack>

        <Stack
          display={isSelectedClinicianLoading ? 'none' : 'flex'}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <AvatarEdit
            editable={false}
            avatar={selectedClinician ? selectedClinician.avatar : ''}
            name={selectedClinician?.firstName}
            name2={selectedClinician?.lastName}
            size="72px"
            font={theme.typography.h3}
          />
          <Typography mt={2} component="p" variant="bodyRegular" color={theme.palette.info.main}>
            {selectedClinician?.email}
          </Typography>
          <Typography component="p" variant="bodyRegular" color={theme.palette.info.main}>
            {selectedClinician?.phone}
          </Typography>
          <Typography component="p" variant="bodyRegular" color={theme.palette.info.main}>
            {`${getAgeFromDateOfBirth(selectedClinician?.birthday)} years old`}
          </Typography>
          <Typography component="p" variant="bodyRegular" color={theme.palette.info.main}>
            {selectedClinician?.patientCount} Patients
          </Typography>
          <Grid container marginTop={3} columnSpacing={2}>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                onClick={handleDismiss}
                fullWidth
                sx={{ borderRadius: '10px', height: '43px' }}
              >
                Dismiss
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </CustomModal>
    </Fragment>
  );
}

export default CliniciansTable;
