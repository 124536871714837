import React, { useEffect, useMemo } from 'react';
import { Box, IconButton, Slider, Typography, useTheme } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SolarPauseBoldSVG from '../../assets/img/solar_pause-bold.svg';
import PlayBoldSVG from '../../assets/img/play-bold.svg';
import { linearToDecibel } from '../../utils/volume-utils';
import { AudioManager } from '../../utils/audio-manager';

export interface NoisePlayerProps {
  isPlay: boolean;
  label: string;
  pan: 'left' | 'right' | 'both';
  value: number;
  handleNoiseThresholdChange: (value: number) => void;
  handlePlay: () => void;
}

function NoisePlayer(props: NoisePlayerProps) {
  const theme = useTheme();
  const toneObj = useMemo(() => {
    const audioManager = new AudioManager();

    const noise = audioManager.getNoise('white');

    noise.volume.value = linearToDecibel(50, -100, -40);
    noise.toDestination();

    return {
      noise,
    };
  }, []);

  useEffect(() => {
    toneObj.noise.volume.value = linearToDecibel(props.value, -100, -40);
    if (props.isPlay) {
      toneObj.noise.start();
    } else {
      toneObj.noise.stop();
    }
  }, [props.isPlay, props.value]);

  useEffect(() => {
    return () => {
      toneObj.noise.stop();
    };
  }, []);

  const handleVolumeIncrease = () => {
    if (props.value < 120) {
      const volume = props.value + 1;
      props.handleNoiseThresholdChange(volume);
    }
  };

  const handleVolumeDecrease = () => {
    if (props.value > 0) {
      const volume = props.value - 1;
      props.handleNoiseThresholdChange(volume);
    }
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    props.handleNoiseThresholdChange(newValue as number);
  };

  return (
    <Box>
      <Typography component="p" variant="h6" gutterBottom>
        {props.label}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box width="90%" display="flex" justifyContent="space-between" alignItems="center">
          <VolumeUpIcon color="primary" />
          <Slider value={props.value} onChange={handleChange} sx={{ marginX: 4 }} step={1} min={0} max={120} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bgcolor={theme.palette.white.main}
            width={140}
            sx={{ borderWidth: '0.5px', borderStyle: 'solid', borderRadius: '10px' }}
          >
            <IconButton id={`btn-volume-decrease-${props.pan}`} size="small" onClick={handleVolumeDecrease}>
              <RemoveCircleOutlineIcon color="primary" />
            </IconButton>
            <Typography id={`valume-${props.pan}`}>{props.value}</Typography>
            <IconButton id={`btn-volume-increase-${props.pan}`} size="small" onClick={handleVolumeIncrease}>
              <ControlPointIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
        <IconButton id={`btn-play-${props.pan}`} size="medium" onClick={props.handlePlay}>
          <Box width={24} height={24} component="img" src={props.isPlay ? SolarPauseBoldSVG : PlayBoldSVG} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default NoisePlayer;
