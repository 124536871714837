import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { GetAllPatientParamType, GetCositHistoryParamType, TransferPatientParamType } from '../../api/patientApi';
import { ClinicianType } from '../clinician/clinicianSlice';
import { PhaseEnum } from '../../utils/enums/phase.enum';
import { SortOrder } from '../../utils/enums/sort-order.enum';
import { SidebarOption } from '../../utils/enums/sidebar-option.enum';

export interface PatientType {
  userId: string;
  patientId: string;
  globalId: string;
  clinicBaseId: string;
  clinicId: string;
  clinicianId: string;
  startDate: string;
  firstName: string;
  lastName: string;
  surname: string;
  email: string;
  avatar: string;
  birthday: string;
  age: number;
  gender: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
  progress: number;
  clinicianGlobalId: string;
  clinicianClinicBaseId: string;
  clinicianFirstName?: string;
  clinicianLastName?: string;
  clinicianAvatar?: string;
  clinicianSurname?: string;
  clinicianBirthday?: string;
  clinicianEmail?: string;
  clinicianPhone?: string;
  clinicianAddressLine1?: string;
  clinicianAddressLine2?: string;
  clinicianCity?: string;
  clinicianZipCode?: string;
  clinicianState?: string;
  clinicianCountry?: string;
  clinicianUserId?: string;
  isCheck?: boolean;
  isNew?: boolean;
  clinicName?: string;
  clinicAvatar?: string;
  dayCount?: number;
  isSoundConfigSet?: boolean;
}

export interface AddEditPatientDataType {
  userId?: string;
  firstName?: string;
  lastName?: string;
  clinicianId?: string;
  surname?: string;
  birthday?: string;
  gender?: string;
  phone?: string;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateId?: number;
  state?: string;
  zipCode?: string;
  countryId?: number;
  country?: string;
  avatar?: string;
}

export interface GoalsType {
  id: number;
  name: string;
}

export interface CositDataType {
  patientId: string | null;
  sleep: string;
  communication: string;
  peace: string;
  concentration: string;
  stress: string;
  goals: GoalsType[];
}

export interface RequestCositDataType {
  patientId: string;
  sleep: string;
  communication: string;
  peace: string;
  concentration: string;
  stress: string;
  goals: number[];
}

export interface AvatarDataType {
  avatarId: string;
  patientId: string;
  tinnitusType: string;
  frequency: number;
  bandwidth: number;
  leftVolume: number;
  rightVolume: number;
  horizontalDirection: number;
  verticalPosition: number;
  horizontalDistance: number;
  createdAt?: Date;
}

export interface RequestTinnitusAvatarHistory {
  patientId: string;
  offset: number;
  limit: number;
}

export interface EqDataType {
  rightNoiseThreshold: number;
  leftNoiseThreshold: number;
  rightEqThreshold: number[];
  leftEqThreshold: number[];
  rightEqMml: number[];
  leftEqMml: number[];
  rightEqThresholdStatus: boolean[];
  leftEqThresholdStatus: boolean[];
  rightEqMmlStatus: boolean[];
  leftEqMmlStatus: boolean[];
}

export interface DbEqType {
  thresholdDb: number[];
  mmlDb: number[];
  tanDb: number[];
  overallThresholdDb:number;
  convertedTan: number[]
}

export interface TreatmentTimelineDetails {
  dayCount: number;
  isComplete: boolean;
  phase: PhaseEnum;
  isUnusedDay: boolean;
}

export interface CositHistory {
  historyId: string;
  goals: string[];
  createdAt: Date;
}

export interface PatientFeedbackDetails {
  dailyFeedback: number[];
  tinnitusProgressFeedback: number[];
  everyFiveDaysFeedback: [number[]];
}

export interface C2CTransferApproveParamType {
  notificationId: string;
  clinicianId: string;
  password: string;
}

export interface C2CTransferRejectParamType {
  notificationId: string;
  password: string;
}

export interface PendingC2CTranferDetailsType {
  globalId: string;
  clinicBaseId: string;
  clinic: {
    clinicId: string;
    name: string;
    avatar: string;
  };
}

export interface IPatientSlice {
  patientsDetails?: PatientType;
  totalPatientCount: number;
  patientList: PatientType[];
  clinicianPatientList: PatientType[];
  componentStatus: { [name: string]: undefined | string | boolean | null | number };
  addEditPatientData?: AddEditPatientDataType;
  transferPatient?: PatientType;
  transferClinician?: ClinicianType;
  patientIds: string[];
  cosit: CositDataType;
  currentClinicianId: string | undefined;
  avatar: AvatarDataType;
  avatarHistoryList: AvatarDataType[];
  totalAvatarHistoryCount: number;
  eqData: EqDataType;
  treatmentTimelineDetails: TreatmentTimelineDetails[];
  cositHistory: CositHistory[];
  totalCositHistoryCount: number;
  patientFeedback: PatientFeedbackDetails;
  pendingC2CTranferDetails: PendingC2CTranferDetailsType | undefined;
  currentSidebarType?: SidebarOption;
  dbEqData: DbEqType;
}

const initialState: IPatientSlice = {
  patientsDetails: undefined,
  totalPatientCount: 0,
  patientList: [],
  clinicianPatientList: [],
  componentStatus: {},
  addEditPatientData: undefined,
  transferPatient: undefined,
  transferClinician: undefined,
  patientIds: [],
  cosit: {
    patientId: '',
    sleep: '',
    communication: '',
    peace: '',
    concentration: '',
    stress: '',
    goals: [],
  },
  currentClinicianId: undefined,
  avatar: {
    avatarId: '',
    patientId: '',
    tinnitusType: '',
    frequency: 0,
    bandwidth: 0,
    leftVolume: 0,
    rightVolume: 0,
    horizontalDirection: 0,
    verticalPosition: 0,
    horizontalDistance: 0,
  },
  avatarHistoryList: [],
  totalAvatarHistoryCount: 0,
  eqData: {
    rightNoiseThreshold: 50,
    leftNoiseThreshold: 50,
    rightEqThreshold: [0, 0, 0, 0, 0, 0],
    leftEqThreshold: [0, 0, 0, 0, 0, 0],
    rightEqMml: [0, 0, 0, 0, 0, 0],
    leftEqMml: [0, 0, 0, 0, 0, 0],
    rightEqThresholdStatus: [true, true, true, true, true, true],
    leftEqThresholdStatus: [true, true, true, true, true, true],
    rightEqMmlStatus: [true, true, true, true, true, true],
    leftEqMmlStatus: [true, true, true, true, true, true],
  },
  treatmentTimelineDetails: [],
  cositHistory: [],
  totalCositHistoryCount: 0,
  patientFeedback: {
    dailyFeedback: [],
    tinnitusProgressFeedback: [],
    everyFiveDaysFeedback: [[]],
  },
  pendingC2CTranferDetails: undefined,
  dbEqData: {
    thresholdDb: [],
    mmlDb: [],
    tanDb: [],
    overallThresholdDb:0,
    convertedTan:[],
  },
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setComponentStatus(state, action: PayloadAction<{ [key: string]: undefined | string | boolean | null | number }>) {
      state.componentStatus = { ...state.componentStatus, ...action.payload };
    },
    clearComponentStatus(state, action: PayloadAction<string[] | undefined>) {
      if (action.payload?.length) {
        state.componentStatus = _.omit(state.componentStatus, action.payload);
      } else {
        state.componentStatus = {};
      }
    },
    setPatientIds(state, action: PayloadAction<string[] | []>) {
      state.patientIds = action.payload;
    },
    setPatientDetails(state, action: PayloadAction<PatientType | undefined>) {
      state.patientsDetails = action.payload;
    },
    setPatientList(state, action: PayloadAction<PatientType[]>) {
      state.patientList = action.payload;
    },
    setClinicianPatientList(state, action: PayloadAction<PatientType[]>) {
      state.clinicianPatientList = action.payload;
    },
    setTotalPatientCount(state, action: PayloadAction<number>) {
      state.totalPatientCount = action.payload;
    },
    getPatientListAsync: (
      state,
      action: PayloadAction<{
        clinicianId: string;
        offset: number;
        limit: number;
        sortBy: string;
        sortOrder: SortOrder;
        searchText?: string;
      }>,
    ) => {},
    setAddEditPatientData(state, action: PayloadAction<AddEditPatientDataType | undefined>) {
      state.addEditPatientData = action.payload;
    },
    setCositData(state, action: PayloadAction<CositDataType>) {
      state.cosit = action.payload;
    },
    setEqData(state, action: PayloadAction<EqDataType>) {
      state.eqData = action.payload;
    },
    setDbEqData(state, action: PayloadAction<DbEqType>) {
      state.dbEqData = action.payload;
    },
    clearEqData(state) {
      state.eqData = {
        rightNoiseThreshold: 50,
        leftNoiseThreshold: 50,
        rightEqThreshold: [0, 0, 0, 0, 0, 0],
        leftEqThreshold: [0, 0, 0, 0, 0, 0],
        rightEqMml: [0, 0, 0, 0, 0, 0],
        leftEqMml: [0, 0, 0, 0, 0, 0],
        rightEqThresholdStatus: [true, true, true, true, true, true],
        leftEqThresholdStatus: [true, true, true, true, true, true],
        rightEqMmlStatus: [true, true, true, true, true, true],
        leftEqMmlStatus: [true, true, true, true, true, true],
      };
    },
    setCurrentClinicianId(state, action: PayloadAction<string | undefined>) {
      state.currentClinicianId = action.payload;
    },
    setTreatmentTimelineDetails(state, action: PayloadAction<TreatmentTimelineDetails[]>) {
      state.treatmentTimelineDetails = action.payload;
    },
    setCositHistory(state, action: PayloadAction<CositHistory[]>) {
      state.cositHistory = action.payload;
    },
    setTotalCositHistoryCount(state, action: PayloadAction<number>) {
      state.totalCositHistoryCount = action.payload;
    },
    setPendingC2CTranferDetails(state, action: PayloadAction<PendingC2CTranferDetailsType>) {
      state.pendingC2CTranferDetails = action.payload;
    },
    createPatientAsync(state, action: PayloadAction<AddEditPatientDataType>) {},
    updatePatientAsync(state, action: PayloadAction<{ patientId: string; data: Partial<AddEditPatientDataType> }>) {},
    getAllPatientListAsync: (state, action: PayloadAction<GetAllPatientParamType>) => {},
    transferPatientAsync: (state, action: PayloadAction<TransferPatientParamType>) => {},
    getPatientAsync: (state, action: PayloadAction<{ userId: string }>) => {},
    getCositDataAsync: (state, action: PayloadAction<{ patientId: string }>) => {},
    createCositDataAsync: (state, action: PayloadAction<RequestCositDataType>) => {},
    updateCositDataAsync: (state, action: PayloadAction<RequestCositDataType>) => {},
    getTinnitusAvatarDataAsync: (state, action: PayloadAction<{ patientId: string }>) => {},
    setTinnitusAvatarData(state, action: PayloadAction<AvatarDataType>) {
      state.avatar = action.payload;
    },
    getTinnitusAvatarHistoryAsync: (state, action: PayloadAction<RequestTinnitusAvatarHistory>) => {},
    setTinnitusAvatarHistory(state, action: PayloadAction<AvatarDataType[]>) {
      state.avatarHistoryList = action.payload;
    },
    setTinnitusAvatarHistoryCount(state, action: PayloadAction<number>) {
      state.totalAvatarHistoryCount = action.payload;
    },
    setCurrentSidebarType(state, action: PayloadAction<SidebarOption | undefined>) {
      state.currentSidebarType = action.payload;
    },
    getSoundConfigurationAsync: (state, action: PayloadAction<{ userId: string }>) => {},
    updateSoundConfigurationAsync: (
      state,
      action: PayloadAction<{
        isSoundConfigSet: boolean;
        userId: string;
        eqData: EqDataType;
        tanRight: number[];
        tanLeft: number[];
        isCompleted: boolean;
      }>,
    ) => {},
    getTreatmentTimelineDetailsAsync: (state, action: PayloadAction<{ patientUserId: string }>) => {},
    getCositHistoryAsync: (state, action: PayloadAction<GetCositHistoryParamType>) => {},
    getAllPatientFeedbackAsync: (state, action: PayloadAction<{ patientId: string }>) => {},
    setAllPatientFeedback(state, action: PayloadAction<PatientFeedbackDetails>) {
      state.patientFeedback = action.payload;
    },
    C2CTransferApproveAsync: (state, action: PayloadAction<C2CTransferApproveParamType>) => {},
    C2CTransferRejectAsync: (state, action: PayloadAction<C2CTransferRejectParamType>) => {},
    C2CTransferPendingDetailsAsync: (state, action: PayloadAction<{ notificationId: string }>) => {},
  },
});

export const { actions: patientActions, reducer: patientReducer } = patientSlice;
